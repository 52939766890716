<template>
  <div>
    <b-form-group
        v-if="type == 'text'"
        :label-for="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
        :label="(displayLabel && !displayErrorOnly)?capitalize($tc(name)):''"
        :class="{'input-group-merge':isGroup}"
    >
      <validation-provider
          v-if="rules != ''"
          #default="{ errors }"
          :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
          :rules="rules"
      >
        <b-input-group
            v-if="isGroup"
            v-show="!displayErrorOnly"
            :class="{'disabled': disabled}"
        >
          <b-input-group-prepend is-text>
            <slot name="prepend">-</slot>
          </b-input-group-prepend>
          <b-form-input
              :id="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
              v-model="localModel"
              :state="errors.length > 0 ? false:null"
              :placeholder="placeholder?placeholder:(isGroup?'':capitalize($tc(name)))"
              @input="$emit('input', $event)"
              @blur="$emit('blur', $event)"
              :disabled="disabled"
              :size="size"
              :plaintext="isPlaintext"
          />
          <b-input-group-append is-text>
            <slot name="append"></slot>
          </b-input-group-append>
        </b-input-group>


        <b-input-group
            v-else-if="withTemplate"
            v-show="!displayErrorOnly"
            :class="{'disabled': disabled}"
        >
          <b-form-input
              :id="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
              v-model="localModel"
              :state="errors.length > 0 ? false:null"
              :placeholder="placeholder?placeholder:capitalize($tc(name))"
              @input="$emit('input', $event)"
              @blur="$emit('blur', $event)"
              :disabled="disabled"
              :size="size"
              :plaintext="isPlaintext"
          />

          <b-input-group-append
              v-b-hover="handleTemplateHover"
              class="cursor-pointer"
              is-text

              @click="isTemplateOpen=!isTemplateOpen"
          >
            <icon
                icon="clone"
                :class="{'text-primary': templateIconHover}"
            />
          </b-input-group-append>

        </b-input-group>

        <b-form-input
            v-else
            v-show="!displayErrorOnly"
            :id="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
            v-model="localModel"
            :state="errors.length > 0 ? false:null"
            :placeholder="placeholder?placeholder:capitalize($tc(name))"
            @input="$emit('input', $event)"
            @blur="$emit('blur', $event)"
            :disabled="disabled"
            :size="size"
            :class="inputClass"
            :plaintext="isPlaintext"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <b-input-group
          v-else-if="isGroup"
          :class="{'disabled': disabled}"
      >
        <b-input-group-prepend is-text>
          <slot name="prepend">-</slot>
        </b-input-group-prepend>
        <b-form-input
            :id="name"
            v-model="localModel"
            :placeholder="placeholder?placeholder:capitalize($tc(name))"
            :disabled="disabled"
            :size="size"
            @input="$emit('input', $event)"
            @blur="$emit('blur', $event)"
        />
      </b-input-group>


      <b-form-input
          v-else
          :id="name"
          v-model="localModel"
          :placeholder="placeholder?placeholder:capitalize($tc(name))"
          :disabled="disabled"
          :class="{'disabled': disabled}"
          :size="size"
          @input="$emit('input', $event)"
          @blur="$emit('blur', $event)"
      />
    </b-form-group>

    <b-form-group
        v-else-if="type == 'siret'"
        :label-for="name"
        :label="capitalize($tc(name))"
    >
      <validation-provider
          v-if="rules != ''"
          #default="{ errors }"
          :name="$t(name)"
          :rules="rules"
      >
        <b-input-group
            :class="{'disabled': disabled}"
        >
          <b-input-group-prepend is-text>
            <slot name="prepend">-</slot>
          </b-input-group-prepend>
          <b-form-input
              :id="name"
              v-model="localModel"
              :state="errors.length > 0 ? false:null"
              :placeholder="capitalize($t('nic'))"
              @input="$emit('input', $event)"
              @blur="$emit('blur', $event)"
              :disabled="disabled"
              :size="size"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <b-input-group
          v-else
          :class="{'disabled': disabled}"
      >
        <b-input-group-prepend is-text>
          <slot name="prepend">-</slot>
        </b-input-group-prepend>
        <b-form-input
            :id="name"
            v-model="localModel"
            :placeholder="placeholder?placeholder:capitalize($tc(name))"
            @input="$emit('input', $event)"
            @blur="$emit('blur', $event)"
            :disabled="disabled"
            :size="size"
        />
      </b-input-group>
    </b-form-group>

    <b-form-group
        v-else-if="type == 'password'"
        :label-for="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
        :label="(displayLabel && !displayErrorOnly)?capitalize($tc(name)):''"
    >
      <validation-provider
          v-if="rules != ''"
          #default="{ errors }"
          :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
          :rules="rules"
      >
        <b-input-group
            v-if="isGroup"
            v-show="!displayErrorOnly"
            :class="{'disabled': disabled}"
        >
          <b-input-group-prepend is-text>
            <slot name="prepend">-</slot>
          </b-input-group-prepend>
          <b-form-input
              :id="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
              v-model="localModel"
              :state="errors.length > 0 ? false:null"
              :placeholder="placeholder?placeholder:capitalize($tc(name))"
              @input="$emit('input', $event)"
              @blur="$emit('blur', $event)"
              :disabled="disabled"
              :size="size"
              type="password"
          />
        </b-input-group>

        <b-form-input
            v-else-if="!isGroup"
            v-show="!displayErrorOnly"
            :id="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
            v-model="localModel"
            :state="errors.length > 0 ? false:null"
            :placeholder="placeholder?placeholder:capitalize($tc(name))"
            @input="$emit('input', $event)"
            @blur="$emit('blur', $event)"
            :disabled="disabled"
            :size="size"
            type="password"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <b-input-group
          v-else-if="isGroup"
          :class="{'disabled': disabled}"
      >
        <b-input-group-prepend is-text>
          <slot name="prepend">-</slot>
        </b-input-group-prepend>
        <b-form-input
            :id="name"
            v-model="localModel"
            :placeholder="placeholder?placeholder:capitalize($tc(name))"
            :disabled="disabled"
            :size="size"
            @input="$emit('input', $event)"
            @blur="$emit('blur', $event)"
            type="password"
        />
      </b-input-group>

      <b-form-input
          v-else
          :id="name"
          v-model="localModel"
          :placeholder="placeholder?placeholder:capitalize($tc(name))"
          :disabled="disabled"
          :class="{'disabled': disabled}"
          :size="size"
          @input="$emit('input', $event)"
          @blur="$emit('blur', $event)"
          type="password"
      />
    </b-form-group>

    <template-input
        v-if="isTemplateOpen"
        class="mt-50"
        :is-open.sync="isTemplateOpen"
        :template-type="templateType"

        @templateSelected="$emit('templateSelected', $event);isTemplateOpen=false"
    />
  </div>
</template>

<script>
import { ValidationProvider }                            from 'vee-validate'
import { required, email, phone, nic, decimal, integer } from '@/utils/validations/validations'
import { capitalize }                                    from '@/utils/filter'
import { ref, watch }                                    from '@vue/composition-api'
import TemplateInput                                     from './TemplateInput.vue'

export default {
  components: {
    TemplateInput,
    ValidationProvider
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    isGroup: {
      type: Boolean,
      default: false
    },
    model: {
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    listIndex: {
      type: Number,
      default: -1
    },
    placeholder: {
      type: String,
    },
    displayErrorOnly: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    inputClass: {
      default: null
    },
    withTemplate: {
      type: Boolean,
      default: false
    },
    templateType: {
      type: String
    },
    isPlaintext : {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)
    const templateIconHover = ref(false)
    const isTemplateOpen = ref(false)
    // const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, (val) => {
      emit('update:model', localModel.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateLocal = (val = null) => {
      if (val) {
        localModel.value = val
      } else {
        localModel.value = props.model
      }
    }

    const handleTemplateHover = hover => {
      templateIconHover.value = hover
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      required,
      email,
      phone,
      nic,
      decimal,
      integer,

      capitalize,

      localModel,
      templateIconHover,
      isTemplateOpen,
      // componentKey,

      updateLocal,
      handleTemplateHover,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    model: function (val) {
      if (typeof val != 'undefined') {
        this.localModel = JSON.parse(JSON.stringify(val))
      }
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>