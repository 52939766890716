<template>
  <div>
    <div
        v-if="isOpenLocal"
        class="shadow p-1 bg-white rounded"
    >
      <!--      Search-->
      <b-input-group class="input-group-merge">
        <b-form-input
            :placeholder="$t('Search')"
            class="search-product"
            v-model="localSearchQuery"
        />
        <b-input-group-append is-text>
          <Transition mode="out-in">
            <b-spinner
                v-if="isLoading"
                small
            />

            <icon
                v-else
                icon="search"
                class="text-muted"
            />
          </Transition>
        </b-input-group-append>
      </b-input-group>

      <h6 class="mt-1">{{ capitalize($tc('myTemplate', 2)) }}</h6>
      <div
          v-for="privateTemplate in privateTemplates"
          class="ml-50"
      >
        <template-item
            :template="privateTemplate"

            @click="privateTemplate._isLoading=true;$emit('templateSelected', privateTemplate)"
        />
      </div>
      <span
          v-if="privateTemplates.length == 0"
          class="ml-50"
      >
        <small>
          <i>{{ capitalize($tc('noTemplate', 1)) }}</i>
        </small>
      </span>

      <h6 class="mt-1">{{ capitalize($tc('publicTemplate', 2)) }}</h6>
      <div
          v-for="publicTemplate in publicTemplates"
          class="ml-50"
      >
        <template-item
            :template="publicTemplate"

            @click="publicTemplate._isLoading=true;$emit('templateSelected', publicTemplate)"
        />
      </div>
      <span
          v-if="publicTemplates.length == 0"
          class="ml-50"
      >
        <small>
          <i>{{ capitalize($tc('noTemplate', 1)) }}</i>
        </small>
      </span>


    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onUnmounted } from '@vue/composition-api'
import InputField                            from './Input.vue'
import useTemplateAPI           from '../../store/template/useTemplateApi'
import { clone, getUserData }   from '../../utils/utils'
import { capitalize }           from '../../utils/filter'
import TemplateItem             from './components/TemplateItem.vue'

export default {
  components: { TemplateItem, InputField },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    templateType: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localSearchQuery = ref('')
    const defaultFilterPayload = ref([
      {
        sectionLogicOperator: 'AND',
        sectionFilters: [
          {
            logicOperator: 'AND',
            field: 'shared',
            operator: 'equal',
            value: 'private'
          },
          {
            logicOperator: 'AND',
            field: 'createdBy',
            operator: 'equal',
            value: getUserData().id
          },
          {
            logicOperator: 'AND',
            field: 'type',
            operator: 'equal',
            value: props.templateType
          }
        ]
      },
      {
        sectionLogicOperator: 'OR',
        sectionFilters: [
          {
            logicOperator: 'AND',
            field: 'shared',
            operator: 'equal',
            value: 'public'
          },
          {
            logicOperator: 'AND',
            field: 'type',
            operator: 'equal',
            value: props.templateType
          }
        ]
      }
    ])
    const filterPayload = ref([])
    const isLoading = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteTemplates } = useTemplateAPI()

    const isOpenLocal = computed({
      get () {
        return props.isOpen
      },
      set (val) {
        emit('update:isOpen', val)
      }
    })

    const privateTemplates = computed(() => {
      return autocompleteTemplates.value.filter(t => t.shared == 'private')
    })

    const publicTemplates = computed(() => {
      return autocompleteTemplates.value.filter(t => t.shared == 'public')
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    let delayTimer
    watch(localSearchQuery, val => {
      clearTimeout(delayTimer)
      delayTimer = setTimeout(() => {
        populateAutocompletePayload()
      }, 500)
    })

    watch(filterPayload, val => {
      fetchAutocompleteTemplates({
        filters: val
      })
          .then(response => {
            isLoading.value = false
          })
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchAutocompleteTemplates } = useTemplateAPI()

    const populateAutocompletePayload = () => {
      isLoading.value = true
      filterPayload.value = clone(defaultFilterPayload.value)

      filterPayload.value[0].sectionFilters.push({
        logicOperator: 'AND',
        field: 'name',
        operator: 'contains',
        value: localSearchQuery.value
      })
      filterPayload.value[1].sectionFilters.push({
        logicOperator: 'AND',
        field: 'name',
        operator: 'contains',
        value: localSearchQuery.value
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    populateAutocompletePayload()

    return {
      // Components

      // Data
      localSearchQuery,
      isLoading,

      // Computed
      isOpenLocal,
      privateTemplates,
      publicTemplates,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>