<template>
  <div
      class="cursor-pointer rounded-lg "
      :class="{'bg-secondary bg-lighten-6': isHovered}"
      v-b-hover="hoverHandler"

      @click="$emit('click')"
  >
    <div class="py-50 px-1">
      <icon
          :icon="template._icon.icon"
          class="mr-50"
      />
      {{ template.name }}

      <b-spinner
          v-if="'_isLoading' in template && template._isLoading == true"
          small
          class="ml-50"
      />

    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onUnmounted } from '@vue/composition-api'

export default {
  components: {},
  props: {
    template: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const hoverHandler = hover => {
      isHovered.value = hover
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onUnmounted(() => {
      delete props.template._isLoading
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isHovered,

      // Computed

      // Methods
      hoverHandler,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>